<template>
  <div>
    <a-drawer
      :maskClosable="false"
      :title="title"
      :width="widthAdd"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <div v-if="type == 0">
        <p class="ms ms-reg">
          已有账号？
          <span
            @click="
              type = 1;
              title = '登录';
            "
            style="color: #69a4fa; cursor: pointer"
            >前往登录</span
          >
        </p>
        <!-- <a-steps :current="current" size="small">
          <a-step title="绑定手机" />
          <a-step title="账号信息" />
          <a-step title="设置密码" />
        </a-steps> -->
        <!-- <el-step title="账号信息"></el-step>
          <el-step title="设置密码"></el-step>
        </el-steps> -->
        <!-- <el-steps :active="current" finish-status="success">
          <el-step title="绑定手机"></el-step>
          <el-step title="账号信息"></el-step>
          <el-step title="设置密码"></el-step>
        </el-steps> -->
         <el-steps :active="current"  finish-status="success" align-center>
          <el-step title="绑定手机"></el-step>
          <el-step title="账号信息" ></el-step>
          <el-step title="设置密码" ></el-step>
        </el-steps>
        <regCom
          class="reg-com"
          @submitFun="submitFun(arguments)"
          v-show="current == 0"
        ></regCom>
        <regDe
          class="reg-com reg-reg"
          @submitFun="submitFun(arguments)"
          v-show="current == 1"
        ></regDe>
        <regPass
          class="reg-com"
          @submitFun="submitFun(arguments)"
          v-show="current == 2"
        ></regPass>
      </div>
      <div v-if="type == 1">
        <p class="ms">
          还没有账号？<span
            @click="
              type = 0;
              title = '注册';
              current = 0;
            "
            style="color: #69a4fa; cursor: pointer"
            >免费注册</span
          >
        </p>
        <div class="reg-com reg-login">
          <login @loginFun="loginFun(arguments)" :pass="true"></login>
        </div>
      </div>
      <div v-if="type == 2">
        <p class="ms">
          返回,
          <span
            @click="
              type = 1;
              title = '登录';
            "
            style="color: #69a4fa; cursor: pointer"
            >前往登录</span
          >
        </p>
        <!-- <a-steps :current="currentPass" size="small">
          <a-step title="确认账号" sub-title="" description="" />
          <a-step title="验证方式" sub-title="" description="" />
          <a-step title="重置密码" description="" />
        </a-steps> -->
        <el-steps :active="currentPass"  finish-status="success" align-center>
          <el-step title="确认账号"></el-step>
          <el-step title="验证方式"></el-step>
          <el-step title="重置密码"></el-step>
        </el-steps>
        <!-- <el-steps :active="currentPass" simple>
          <el-step title="步骤 1" icon="el-icon-edit"></el-step>
          <el-step title="步骤 2" icon="el-icon-upload"></el-step>
          <el-step title="步骤 3" icon="el-icon-picture"></el-step>
        </el-steps> -->
        <div class="reg-com">
          <passPhone @currentFun="currentFun(arguments)"></passPhone>
        </div>
      </div>
    </a-drawer>
    <a-modal
      title="Title"
      :visible="visibleIphon"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p>{{ ModalText }}</p>
    </a-modal>
  </div>
</template>

<script>
//注册
import regCom from "@/components/regCom.vue";
import regDe from "@/components/regDe.vue";
import regPass from "@/components/regPass.vue";
//注册
import login from "@/components/login.vue";
import passPhone from "@/components/passPhone.vue";

export default {
  props: {
    //注册
    visible: Boolean,
    visibleIphon: Boolean,
    //注册
  },
  components: {
    //注册
    regCom,
    regDe,
    regPass,
    //注册
    login,
    passPhone,
  },
  data() {
    return {
      widthAdd:620,
      //注册
      ModalText: "Content of the modal",
      title: "登录",
      confirmLoading: false,
      current: 0,
      // 注册0、登录1、密码2
      type: 1,
      currentPass: 0,
      //注册
      //   visible:false
    };
  },
  mounted() {
    if(document.documentElement.clientWidth<=800){
      this.widthAdd = '100%'
    }
    console.log(this.type);
  },
  methods: {
    //注册
    onClose() {
      this.visible = false;
      this.$emit("regFun", this.visible, false);
    },

    handleOk() {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visibleIphon = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel() {
      console.log("Clicked cancel button");
      this.visibleIphon = false;
    },

    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    submitFun(val) {
       
      console.log(val);
      this.current = val[0];
      if (val[2] == 0) {
        this.title = "登录";
      } else if (val[2] == 1) {
        this.title = "注册";
      } else {
        this.title = "找回密码";
      }
      this.type = val[1];
      console.log(this.type);
    },
    //注册
    // 登录
    callback(key) {
      console.log(key);
    },
    loginFun(val) {
      this.visible = val[0];
      // 可以登录了
      if (val[1] == "ok") {
        this.$emit("regFun", this.visible, true);
      }
      // 前往忘记密码
      if (val[2]) {
        this.type = val[2];
        this.title = "找回密码";
        this.currentPass = 0;
        console.log(this.type);
      }
    },
    currentFun(val) {
       
      this.currentPass = val[0];
      console.log(this.currentPass);
      // 可以登录了
      this.type = val[1];
      if (val[1] == 1) {
        this.title = "登录";
      }
    },
  },
};
</script>
<style>
.ms {
  text-align: center;
  margin: 20px 0 50px;
  font-weight: bold;
}
.ms-reg {
  margin: 20px 0;
}
.reg-com {
  padding: 50px 20px 20px !important;
  width: 412px;
  margin: 0 auto !important;
}
.reg-reg {
  padding: 30px 20px 20px !important;
}
.reg-login {
  padding-top: 0 !important;
}
.el-step__title{
  font-size:13px !important;
}
@media  (max-width:800px) {
  .reg-com {

  width: 100%;
  margin: 0 auto !important;
}
.reg-com-m{
  padding: 0px 0 0  !important;
}
}

</style>
