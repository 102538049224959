<template>
  <div>
    <div v-if="screenwidth > 900">
      <div class="header">
        <div class="left">
          <div class="left-name" @click="look('./')">
            <img src="../images/logo.png" width="150px" />
          </div>
          <div class="header-top">
            <a-menu v-model="current" mode="horizontal">
              <a-menu-item key="" @click="look('./index')"> 首页 </a-menu-item>
              <!-- <a-menu-item key="about" @click="look('./aboutN')">
                关于
              </a-menu-item> -->
              <a-menu-item
                key="result"
                @click="look('./result', true)"
                v-if="role"
              >
                成绩查询
              </a-menu-item>

              <a-menu-item
                key="enroll"
                @click="look('./enroll', true)"
                v-if="role"
              >
                我的报名
              </a-menu-item>
              <a-menu-item key="selfDetail" @click="look('./selfDetail', true)">
                个人信息
              </a-menu-item>
              <a-menu-item key="info" @click="look('./info', false)">
                通知|新闻
              </a-menu-item>
            </a-menu>
          </div>
        </div>
        <div class="header-right">
          <span style="margin-right: 30px; margin-top: 6px" @click="look('/')"
            >查看更多比赛</span
          >
          <a-button type="primary" v-if="!loginOk" @click="login">
            登录
          </a-button>
          <!-- <a-tooltip placement="bottom">
            <template slot="title">
              <p @click="out" style="cursor: pointer">退出登录</p>
            </template>

            <p
              v-if="loginOk"
              style="
                font-size: 16px;
                font-weight: bold;
                color: rgb(75 75 75);
                margin-top: 4px;
                margin-bottom: 0;
              "
            >
              欢迎回来！{{ user }}
            </p>
          </a-tooltip> -->
          <a-popover title="" placement="bottom">
            <template slot="content">
              <p @click="out" style="cursor: pointer">退出登录</p>
            </template>
            <p
              v-if="loginOk"
              style="
                font-size: 16px;
                font-weight: bold;
                color: rgb(75 75 75);
                margin-top: 4px;
                margin-bottom: 0;
              "
            >
              欢迎回来！{{ user }}
            </p>
          </a-popover>
        </div>
      </div>

      <reg
        :visible="visible"
        :visibleIphon="visibleIphon"
        :typePran="1"
        @regFun="regFun(arguments)"
        v-if="visible == true"
      >
      </reg>
    </div>
    <div
      v-else
      style="
        top: 0;
        position: fixed;
        width: 100%;
        background: #fff;
        z-index: 99;
      "
    >
      <div class="header-m">
        <div class="left">
          <div class="left-name" @click="look('./index')">
            <img src="../images/logo.png" width="150px" />
          </div>
          <!-- <div class="header-top">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="" @click="look('./index')"> 首页 </a-menu-item>
            <a-menu-item key="about" @click="look('./aboutN')">
              关于
            </a-menu-item>
            <a-menu-item key="result" @click="look('./result', true)">
              成绩查询
            </a-menu-item>

            <a-menu-item key="enroll" @click="look('./enroll', true)">
              我的报名
            </a-menu-item>
            <a-menu-item key="selfDetail" @click="look('./selfDetail', true)">
              个人信息
            </a-menu-item>
      
          </a-menu>
        </div> -->
        </div>
        <div class="header-right">
          <a-icon
            type="close"
            v-if="screenShow"
            @click="changeHeader"
            style="font-size: 24px"
          />
          <a-icon
            type="unordered-list"
            v-else
            @click="changeHeader"
            style="font-size: 24px"
          />

          <!-- <span style="margin-right: 30px; margin-top: 6px" @click="look('/')"
          >查看更多比赛</span
        >
        <a-button type="primary" v-if="!loginOk" @click="showDrawer">
          登录
        </a-button>

        <a-popover title="">
          <template slot="content">
            <p @click="out" style="cursor: pointer">退出登录</p>
          </template>
          <p
            v-if="loginOk"
            style="
              font-size: 16px;
              font-weight: bold;
              color: rgb(75 75 75);
              margin-top: 4px;
              margin-bottom: 0;
            "
          >
            欢迎回来！{{ user }}
          </p>
        </a-popover> -->
        </div>
      </div>
      <ul v-if="screenShow" class="m-ul">
        <li @click="look('./index')" :class="currentrStr == '' ? 'active' : ''">
          首页
        </li>
        <!-- <li
          @click="look('./aboutN')"
          :class="currentrStr == 'adout' ? 'active' : ''"
        >
          关于
        </li> -->
        <li
          @click="look('./result', true)"
          :class="currentrStr == 'result' ? 'active' : ''"
          v-if="role"
        >
          成绩查询
        </li>
        <li
          @click="look('./enroll', true)"
          :class="currentrStr == 'enroll' ? 'active' : ''"
          v-if="role"
        >
          我的报名
        </li>
        <li
          @click="look('./selfDetail', true)"
          :class="currentrStr == 'selfDetail' ? 'active' : ''"
        >
          个人信息
        </li>
        <li
          @click="look('./info', true)"
          :class="currentrStr == 'info' ? 'active' : ''"
        >
          通知|新闻
        </li>
        <li @click="look('/')">查看更多比赛</li>
        <li v-if="!loginOk">
          <a-button type="primary" @click="login"> 登录 </a-button>
        </li>
        <li v-if="loginOk">
          <p
            style="
              font-size: 16px;
              font-weight: bold;
              color: rgb(75 75 75);
              margin-top: 4px;
              margin-bottom: 0;
            "
          >
            欢迎回来！{{ user }}
            <span @click="out" style="color: #3c90ee"> 【 退出登录 】</span>
          </p>
        </li>
      </ul>
    </div>
    <div class="header header-fixed">
      <div class="left">
        <div class="left-name"></div>
        <div class="header-top">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="adout" @click="look('./aboutN')"></a-menu-item>
            <a-menu-item key="schedule"></a-menu-item>
            <a-menu-item key="faq"></a-menu-item>
            <a-menu-item key="rules"></a-menu-item>
            <a-menu-item key="com"></a-menu-item>
            <a-menu-item key="arc"></a-menu-item>
          </a-menu>
        </div>
      </div>

      <div class="header-right"></div>
    </div>
  </div>
</template>
<script>
import reg from "@/components/reg.vue";
export default {
  components: {
    reg,
  },
  data() {
    return {
      screenwidth: document.body.clientWidth,
      role: false,
      activeKey: null,
      current: [],
      name: "",
      visible: false,
      visibleIphon: false,
      loginOk: false,
      user: "",
      timer: null,
    };
  },
  props: {
    currentrStr: String,
    screenShow: {
      type: Boolean,
      default: false,
    },
  },
  // computed: {
  //   leftnavitems: function () {
  //     return this.screenwidth >= 600 ? this.navitems : {};
  //   },

  //   rightnavitems: function () {
  //     return this.screenwidth < 600 ? this.navitems : {};
  //   },
  // },

  // watch: {
  //   screenwidth(newvalue) {
  //     // 为了避免频繁触发resize函数导致页面卡顿，使用定时器

  //     if (!this.timer) {
  //       // 一旦监听到的screenwidth值改变，就将其重新赋给data里的screenwidth

  //       this.screenwidth = newvalue;
  //       console.log(this.screenwidth)

  //       this.timer = true;

  //       setTimeout(() => {
  //         //console.log(this.screenwidth);

  //         this.timer = false;
  //       }, 400);
  //     }
  //   },
  // },

  mounted() {
    this.role =
      window.localStorage.getItem("anjing-roleName") == "教师" ||
      window.localStorage.getItem("anjing-roleName") == "企业"
        ? false
        : true;
    console.log(this.role);

    window.onresize = () => {
      this.screenwidth = document.body.clientWidth;

      // if (this.screenwidth <= 900) {
      //   this.screenShow = false;
      // } else {
      //   this.screenShow = true;
      // }
    };

    // if (
    //    window.localStorage.getItem("anjing-setLogin") &&
    //    window.localStorage.getItem("anjing-setLogin") != ""
    // ) {
    //   this.$message.info("您还没有登录，请先登录！");
    //   this.visible = true;
    //    window.localStorage.setItem("anjing-setLogin", "");
    // }
    if (
      window.localStorage.getItem("anjing-tokenName") &&
      window.localStorage.getItem("anjing-tokenName") != ""
    ) {
      this.user = window.localStorage.getItem("anjing-tokenName");
      this.loginOk = true;
    }
    this.name = window.localStorage.getItem("name");
    // this.current.push( window.localStorage.getItem('current'))
    this.current.push(this.currentrStr);
    // console.log( this.current)
  },
  methods: {
    login() {
      this.$router.push({
        path: "./login-reg-pass",
        query: { url: "./index" },
      });
    },
    changeHeader() {
      this.screenShow = !this.screenShow;
    },
    out() {
      let data = {
        op: "logout",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("登出");
              },
            });
            window.localStorage.setItem("anjing-tokenName", "");
            window.localStorage.setItem("anjing-roleName", "");
            // window.localStorage.setItem("anjing-longxin", '');
            _this.loginOk = false;
            _this.$router.push({ path: "./" });
          } else {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("退出");
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    look(URL, token = false) {
      // this.screenShow =false
      if (token) {
        let data = {
          op: "mylist",
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/scores.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              _this.$router.push({ path: URL });
            } else if (response.data.value == "sessionerror") {
              // 未登录
              _this.$message.info(response.data.msg);
              setTimeout(() => {
                _this.$router.push({
                  path: "./login-reg-pass",
                  query: { url: "./index" },
                });
              }, 800);
            } else {
              // _this.$message.info(response.data.msg);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$router.push({ path: URL });
        if (URL == "./index") {
          this.$router.push({
            path: URL,
            query: { TYPE: window.localStorage.getItem("anjing-urltype") },
          });
        }
      }
    },
    showModal() {
      this.visibleIphon = true;
    },
    showDrawer() {
      this.visible = true;
    },
    regFun(val) {
      this.visible = val[0];
      if (val[1]) {
        this.loginOk = true;
        this.user = window.localStorage.getItem("anjing-tokenName");
        location.reload();
      }
    },
  },
};
</script>
<style scoped>
.m-ul {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
}
.m-ul li {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 22px;
}
.header {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 3px 7px rgb(227 227 227);
  padding: 0 20px;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1001;
}
.header-fixed {
  position: inherit;
  box-shadow: none;
}
.left {
  display: flex;
  justify-content: left;
  line-height: 48px;
}
/deep/ .ant-menu-horizontal {
  border: none;
}
.left-name {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
}
.header-right {
  padding-top: 8px;
  cursor: pointer;
  color: #1e80ff;
  display: flex;
}
.active {
  background: #dff3fc;
}
.header-m {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 1px 3px 7px rgb(227 227 227);
}
</style>
