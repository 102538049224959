<template>
  <div>
    <heade currentrStr=""></heade>
    <div class="common-width" style="padding: 10px">
      <a-breadcrumb style="margin-top: 20px; text-align: left">
        <a-breadcrumb-item
          ><a href="javascript:;" @click="to('./index')"
            >上一页</a
          ></a-breadcrumb-item
        >
        <a-breadcrumb-item>常见问题</a-breadcrumb-item>
      </a-breadcrumb>
      <div style="text-align: left; margin-top: 20px">
        <p class="look-del look-title">
          2022全国大学生计算机系统能力培养大赛编译系统设计赛的信息
        </p>
        <ul>
          <li>
            <h3>报名截至日期是？截止前可以修改报名信息吗？</h3>
            <p class="pad-left">报名截止日前（4月30日），可以修改报名信息。</p>
          </li>
          <li>
            <h3>报名需要的扫描件可以直接拍照吗？</h3>
            <p class="pad-left">可以。</p>
          </li>
          <li>
            <h3>工作证可以用校园一卡通代替吗？</h3>
            <p class="pad-left">可以。</p>
          </li>
          <li>
            <h3>组队能否超过三人？</h3>
            <p class="pad-left">不可以，最多三人。</p>
          </li>
          <li>
            <h3>报名表签字处需要谁的签字？</h3>
            <p class="pad-left">
              需要学院主管教学领导签字。如果因为疫情或者其它不可抗力，报名日期截止前无法签名，可以先报名，以后再补，报名表内做个备注
            </p>
          </li>
          <li>
            <h3>参赛涉及哪些费用？</h3>
            <p class="pad-left">
              1. 大赛不收取任何报名费、参赛费、评审费及技术平台购买费等费用。
            </p>
            <p class="pad-left">
              2.
              选手及指导老师在全国总决赛期间产生的交通、住宿、用餐等费用自理。
            </p>
          </li>
          <li>
            <h3>开发板免费提供吗？</h3>
            <p class="pad-left">
              报名结束以后，大赛组委会统一向参赛队免费发放。赛后开发板不收回。
            </p>
          </li>
          <li>
            <h3>自己如何购买开发板？</h3>
            <p class="pad-left">
              <a
                href="https://item.taobao.com/item.htm?spm=a1z09.2.0.0.3c702e8dMLVWpb&id=591616120470&_u=q3pf8bo634d4"
                target="_blank"
                >点击此处前往购买。</a
              >
            </p>
          </li>
          <li>
            <h3>哪些同学可以参赛？</h3>
            <p class="pad-left">全国普通高等学校全日制在校生均可参赛。</p>
          </li>
          <li>
            <h3>能否使用官方BSP及standalone包中的库函数？</h3>
            <p class="pad-left">
              只要不涉及学术诚信，不是严格禁止的，且可以通过评测机编译的，都可以用。根据组委会的精神，会给大家尽量大的自由度。
            </p>
          </li>
          <li>
            <h3>OS功能挑战赛道的题目什么时候发布？</h3>
            <p class="pad-left">
              组委会将在2月28日确定并对外正式公布“OS功能挑战”题目。
            </p>
          </li>
          <li>
            <h3>参赛队指导教师人数限制？</h3>
            <p class="pad-left">至少一名指导教师，最多不超过两名指导教师。</p>
          </li>
          <li>
            <h3>功能赛是在所有题目里自由选择吗？每个参赛队要求选择几道题？</h3>
            <p class="pad-left">一个队伍一道题，可以自由选择。</p>
          </li>
          <li>
            <h3>功能挑战赛道报名时需要选择题目吗？</h3>
            <p class="pad-left">不需要。</p>
          </li>
          <li>
            <h3>比赛过程中若需要提供个人照片，有什么要求？</h3>
            <p class="pad-left">面部清晰即可。</p>
          </li>
          <li>
            <h3>MMU是否确实可靠，尤其是多处理器下？</h3>
            <p class="pad-left">不保证。需要用户自行测试并take risk。</p>
          </li>
          <li>
            <h3>功能挑战赛道和内核实现赛道报名表是一样的吗？</h3>
            <p class="pad-left">是一样的。</p>
          </li>
          <li>
            <h3>K210的MMU是符合RISC-V的分页功能的标准？</h3>
            <p class="pad-left">
              现在芯片支持mmu
              sv39模式（39位虚地址），三级页表；具体的可以看下官方的资料。 文档
              riscv-privileged-v1.10.pdf 中： c) mmu部分，请参考 Sv39:
              Page-Based 39-bit Virtual-Memory
              System和相关章节，我们系统用的是39位虚拟地址；也请参考提供的软件代码；
            </p>
          </li>

        </ul>
           <p class="look-del look-title">
          开发板资料汇总
        </p>
         <ul>
          <li>
            <h3>由芯片制造商嘉楠提供的文档（芯片型号为勘智k210）:</h3>
            <p class="pad-left"><a href='https://canaan-creative.com/developer' target="_blank" >https://canaan-creative.com/developer</a></p>
          </li>
           <li>
            <h3>由开发板制造商矽速科技提供的文档（开发版型号为Maix-Dock）：</h3>
            <p class="pad-left"><a href='http://cn.dl.sipeed.com/' target="_blank" >http://cn.dl.sipeed.com/</a></p>
          </li>
            <li>
            <h3>risc-v官方文档：</h3>
            <p class="pad-left"><a href='https://riscv.org/technical/specifications/' target="_blank" >https://riscv.org/technical/specifications/</a></p>
            <p>（注意：K210使用的特权级标准版本为1.9.1。）</p>
          </li>
            <li>
            <h3>相关硬件接口文档及教程参考信息：</h3>
            <p class="pad-left"><a href='https://github.com/kendryte/kendryte-doc-datasheet/blob/master/zh-Hans/SUMMARY.md
' target="_blank" >https://github.com/kendryte/kendryte-doc-datasheet/blob/master/zh-Hans/SUMMARY.md
</a></p>
<p class="pad-left"><a href='https://github.com/oscomp/os-competition-info/blob/main/ref-info.md' target="_blank" >https://github.com/oscomp/os-competition-info/blob/main/ref-info.md</a></p>
          </li>
            <li>
            <h3>K210官方SDK中没有支持MMU。如果进行MMU开发可以参考：</h3>
            <p class="pad-left"><a href='https://gitlab.educg.net/wangmingjian/os-comp-qa-archive/-/tree/master/maix%20mmu%20fats' target="_blank" >https://gitlab.educg.net/wangmingjian/os-comp-qa-archive/-/tree/master/maix%20mmu%20fats</a></p>
          </li>
          
         </ul>
      </div>
    </div>
  </div>
</template>
<script>
import heade from "@/components/header.vue";
export default {
  components: {
    heade,
  },
  data() {
    return {
      title: window.localStorage.getItem("anjing-parent"),
    };
  },
  methods:{  to(url) {
      this.$router.push({ path: url });
    },

  }
};
</script>
<style scoped>
.look-del {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  padding-top: 20px;
}
.look-title {
  margin-bottom: 20px;
  padding-top: 0;
}
.pad-left {
  padding: 5px 0;
  padding-left: 13px;
  text-indent: -13px;
}
</style>